import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import axios from "axios";
import Loader from "../../components/loader";
import Cookies from "js-cookie";
import { Button, PageHeader } from "antd";
import "../../styles/main.sass";

import CatSez from "../../data/sezioni-categorie";
import { StaticImage } from "gatsby-plugin-image";

function Dashboard() {
  const [data, setdata] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!Cookies.get("cmac")) {
      console.log("si");
      window.location.href = "/dashboard/login";
    } else {
      axios
        .get(
          "https://concorsopaoloferro.edu.it/concorso-api/dashboard/dashboard-data.php"
        )
        .then((result) => {
          setdata(result.data);
          setLoading(false);
          console.log(result.data);
        });
    }
  }, []);

  function scaricaCSV(type) {
    axios.post(
      "https://concorsopaoloferro.edu.it/concorso-api/dashboard/dashboard-data.php",
      {
        type: type,
      }
    );
  }

  if (loading) return <Loader></Loader>;

  return (
    <>
      <nav>
        <div className="logo">
          <StaticImage src="../../images/logo.png" alt="Logo dashboard" />
        </div>
      </nav>
      <main>
        <h1 style={{ textAlign: "center", marginTop: 80, fontSize: 40 }}>
          Dashboard
        </h1>
        <div className="dashboard-w">
          <div className="counter-w">
            <h2>Iscritti Online</h2>
            <div className="counter">{data["onlineNum"]}</div>
            <Link to="./online-table-select/">
              <Button style={{ marginTop: 20 }} type="primary">
                Vedi per categoria
              </Button>
            </Link>
            <Link to="./all-online-table">
              <Button style={{ marginTop: 20 }} type="secondary">
                Vedi tutti
              </Button>
            </Link>
            <a
              href="https://concorsopaoloferro.edu.it/concorso-api/dashboard/generacsv.php?reg=d&type=online"
              target="_blank"
            >
              <Button style={{ marginTop: 20 }} type="secondary">
                Scarica CSV iscritti online
              </Button>
            </a>
          </div>
          <div className="counter-w">
            <h2>Iscritti in presenza</h2>
            <div className="counter">{data["offlineNum"]}</div>
            <Link to="./offline-table-select/">
              <Button style={{ marginTop: 20 }} type="primary">
                Vedi per categoria
              </Button>
            </Link>
            <Link to="./all-offline-table">
              <Button style={{ marginTop: 20 }} type="secondary">
                Vedi tutti
              </Button>
            </Link>
            <a
              href="https://concorsopaoloferro.edu.it/concorso-api/dashboard/generacsv.php?reg=d&type=offline"
              target="_blank"
            >
              <Button style={{ marginTop: 20 }} type="secondary">
                Scarica CSV iscritti in presenza
              </Button>
            </a>
          </div>
        </div>
      </main>
    </>
  );
}

export default Dashboard;
